import { NgModule, ViewContainerRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';


import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { AuthenticationService } from './services/firestore/firebase-authentication.service';
import { UserDataService } from './services/data-services/user-data.service';
import { FirestoreService } from './services/firestore/firestore.service';
import { FAQDataService } from './services/data-services/faq-data.service';
import { StorageService } from './services/firestore/filestorage.service';
import { UtilService } from './services/util/util.service';
import { CardServicesService } from './services/card/card-services.service';


import { environment } from '../environments/environment';
import { STATIONDataService } from './services/data-services/station-data.service';
import { TRIPDataService } from './services/data-services/trip-data.service';
import { BOOKINGDataService } from './services/data-services/booking-data.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AgentDataService } from './services/data-services/agent-data.service';
import {  STATIONMANAGERDataService } from './services/data-services/stationmanager-data.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FleetDataService } from './services/data-services/fleet-data.service';

import { Printer } from '@ionic-native/printer/ngx';
import { HighlightedDirective } from './directives/highlighted.directive';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';

import { AngularCreatePdfModule } from 'angular-create-pdf';
import { PrintPagePage } from './pages/print-page/print-page.page';




export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [AppComponent, PrintPagePage,HighlightedDirective],
  entryComponents: [
    PrintPagePage
  ],
  imports: [
    BrowserModule,
    AngularCreatePdfModule,

    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.config),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule, //for messaging
  ],
  providers: [

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UserDataService,
    AgentDataService,
    FAQDataService,
    STATIONDataService,
    STATIONMANAGERDataService,
    TRIPDataService,
    BOOKINGDataService,
    AuthenticationService,
    FirestoreService,
    StorageService,
    UtilService,
    CardServicesService,
    FleetDataService,
    Printer,
    BluetoothSerial,
    FileOpener

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
