
// import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
// import { BaseDataService } from './base-data.service';
// import { FirestoreService } from '../firestore/firestore.service';
// import { UtilService } from '../util/util.service';
// import { BOOKINGDto } from 'src/app/models/booking.model';


// @Injectable()
// export class BOOKINGDataService extends BaseDataService<BOOKINGDto> {
//     constructor(private firestore: FirestoreService, private util: UtilService) {
//         super('booking');
//     }

//     public get(): Observable<BOOKINGDto[]> {
//         return this.firestore.get<BOOKINGDto>(this.baseCollection);
//     }
//     public getAll(): Observable<BOOKINGDto[]> {
//         return this.firestore.getAll<BOOKINGDto>(this.baseCollection);
//     }

//     public getApproved(): Observable<BOOKINGDto[]> {
//         return this.firestore.getApproved<BOOKINGDto>(this.baseCollection);
//     }

//     public getOne(id: string): Observable<BOOKINGDto> {
//         return this.firestore.getOne<BOOKINGDto>(this.baseCollection, id);
//     }

//     public update(data: Partial<BOOKINGDto>): Promise<void> {
//         return this.firestore.update<BOOKINGDto>(this.baseCollection, data.id, data);
//     }

//     public delete(id: string): Promise<any> {
//         return this.firestore.delete(this.baseCollection, id);
//     }

//     public create(data: BOOKINGDto): Promise<void> {
//         return this.firestore.create(this.baseCollection, data);
//     }

//     public getAccountTransaction(uid){
//         return this.firestore.runQueryR('account_transactions',{field:'agentId',operation:'==',searchKey:uid})
//       }
//     public getStationBookings(originStation): Observable<BOOKINGDto[]> {
//         return this.firestore.runQueryR(this.baseCollection,{field:'originStation',operation:'==',searchKey:originStation})
//       }
// }


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { FirestoreService } from '../firestore/firestore.service';
import { UtilService } from '../util/util.service';
import { BOOKINGDto } from 'src/app/models/booking.model';
import { environment } from '../../../environments/environment';


@Injectable()
export class BOOKINGDataService extends BaseDataService<BOOKINGDto> {
    constructor(private firestore: FirestoreService, private util: UtilService) {
        super('booking');
    }

    public get(): Observable<BOOKINGDto[]> {
        return this.firestore.get<BOOKINGDto>(environment.dbPrefix + this.baseCollection);
    }
    public getAll(): Observable<BOOKINGDto[]> {
        return this.firestore.getAll<BOOKINGDto>(environment.dbPrefix + this.baseCollection);
    }

    public getApproved(): Observable<BOOKINGDto[]> {
        return this.firestore.getApproved<BOOKINGDto>(environment.dbPrefix + this.baseCollection);
    }

    public getOne(id: string): Observable<BOOKINGDto> {
        return this.firestore.getOne<BOOKINGDto>(environment.dbPrefix + this.baseCollection, id);
    }

    public update(data: Partial<BOOKINGDto>): Promise<void> {
        return this.firestore.update<BOOKINGDto>(environment.dbPrefix + this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(environment.dbPrefix + this.baseCollection, id);
    }

    public create(data: BOOKINGDto): Promise<void> {
        return this.firestore.create(environment.dbPrefix + this.baseCollection, data);
    }
    public createAgentRequest(data): Promise<void> {
        return this.firestore.create(environment.dbPrefix + 'agentpaymentrequest', data);
    }
    public getStationBookings(originStation): Observable<BOOKINGDto[]> {
                return this.firestore.runQueryR(environment.dbPrefix + this.baseCollection, {field:  'originStation', operation: '==', searchKey: originStation});
              }
}

