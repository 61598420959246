
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { FirestoreService } from '../firestore/firestore.service';
import { UtilService } from '../util/util.service';
import { environment } from '../../../environments/environment';
import { FLEETDto } from 'src/app/models/fleet.model';


@Injectable()
export class FleetDataService extends BaseDataService<FLEETDto> {
    constructor(private firestore: FirestoreService, private util: UtilService) {
        super('fleet');
    }

    public get(): Observable<FLEETDto[]> {                         //get all created by the user
        return this.firestore.get<FLEETDto>(environment.dbPrefix + this.baseCollection);
    }
    public getAll(): Observable<FLEETDto[]> {                       //get all
        return this.firestore.getAll<FLEETDto>(environment.dbPrefix + this.baseCollection);
    }

    public getApproved(): Observable<FLEETDto[]> {                       //get all that user is permitted to get
        return this.firestore.getApproved<FLEETDto>(environment.dbPrefix + this.baseCollection);
    }

    public getOne(id: string): Observable<FLEETDto> {
        return this.firestore.getOne<FLEETDto>(environment.dbPrefix + this.baseCollection, id);
    }

    public update(data: Partial<FLEETDto>): Promise<void> {
        return this.firestore.update<FLEETDto>(environment.dbPrefix + this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(environment.dbPrefix + this.baseCollection, id);
    }

    public create(data: FLEETDto): Promise<void> {
        return this.firestore.create(environment.dbPrefix + this.baseCollection, data);
    }

    public runQueryR(stationname, closed?): Observable<FLEETDto[]> {
        if (closed) {
            // console.log('station name - ', stationname );
            return this.firestore.runQueryR(environment.dbPrefix + this.baseCollection, {field: 'stationId', operation: '==', searchKey: stationname});

        } else {
            return this.firestore.runQueryR(environment.dbPrefix + this.baseCollection, {field: 'stationId', operation: '==', searchKey: stationname});

        }
    }

}
