
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import {TRIPDto} from '../../models/trip.model';
import { FirestoreService } from '../firestore/firestore.service';
import { UtilService } from '../util/util.service';
import { environment } from '../../../environments/environment';


@Injectable()
export class TRIPDataService extends BaseDataService<TRIPDto> {
    constructor(private firestore: FirestoreService, private util: UtilService) {
        super('trip');
    }

    public get(): Observable<TRIPDto[]> {                         //get all created by the user
        return this.firestore.get<TRIPDto>(environment.dbPrefix + this.baseCollection);
    }
    public getAll(): Observable<TRIPDto[]> {                       //get all
        return this.firestore.getAll<TRIPDto>(environment.dbPrefix + this.baseCollection);
    }

    public getApproved(): Observable<TRIPDto[]> {                       //get all that user is permitted to get
        return this.firestore.getApproved<TRIPDto>(environment.dbPrefix + this.baseCollection);
    }

    public getOne(id: string): Observable<TRIPDto> {
        return this.firestore.getOne<TRIPDto>(environment.dbPrefix + this.baseCollection, id);
    }

    public update(data: Partial<TRIPDto>): Promise<void> {
        return this.firestore.update<TRIPDto>(environment.dbPrefix + this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(environment.dbPrefix + this.baseCollection, id);
    }

    public create(data: TRIPDto): Promise<void> {
        // console.log(data);
        return this.firestore.create(environment.dbPrefix + this.baseCollection, data);
    }

    public runQueryR(stationname?, closed?,limited?,cancelled?): Observable<TRIPDto[]> {
        // console.log('here 2',this.util.userType)
        if(this.util.userType === 'stationManager'){
            if (closed && cancelled) {
                console.log('station name - ', stationname );
                return this.firestore.runQueryR(environment.dbPrefix + 'cancelledTrip', {field: 'originStation', operation: '==', searchKey: stationname},limited);
    
            } else if (closed) {
                console.log('station name - ', stationname );
                return this.firestore.runQueryR(environment.dbPrefix + 'closedtrip', {field: 'originStation', operation: '==', searchKey: stationname},limited);
    
            }
             else {
                return this.firestore.runQueryR(environment.dbPrefix + this.baseCollection, {field: 'originStation', operation: '==', searchKey: stationname});
            }
        } else {
            const currentTime = Date.now() - 24*60*60*1000;
            // console.log('here',environment.dbPrefix+ this.baseCollection, currentTime)
            return this.firestore.runQueryR(environment.dbPrefix + this.baseCollection, {field: 'tripDate', operation: '>=', searchKey: currentTime});
        }
       
    }

}
