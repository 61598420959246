import { Component } from '@angular/core';




import { HttpClientModule, HttpClient } from '@angular/common/http';



import { Platform, ModalController, MenuController } from '@ionic/angular';
import { AuthenticationService } from './services/firestore/firebase-authentication.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { UtilService } from './services/util/util.service';
import { environment } from '../environments/environment';

import { Router } from '@angular/router';
import { Plugins, StatusBarStyle } from '@capacitor/core';
const { StatusBar, SplashScreen } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  sidemenu = 1;
  showChildren = '';
 

  public agentDetails;
  public uid: string;
  public stationData;
  public permissions;
  public env;

  constructor(
    private platform: Platform,
    private authService: AuthenticationService,
    public util: UtilService,
    private route: Router,
    public modalCtrl: ModalController,
    private menuCtrl: MenuController,
  ) {
    this.initializeApp();
    this.env = environment.dbPrefix;
    // console.log( this.env)

  }

  logout() {
    // console.log('logout item');
    this.authService.logout().then(() => {
      this.util.navigate('login', false);
    });
    this.menuCtrl.toggle();
  }

  welcome() {
    this.route.navigate(['home']);
    this.menuCtrl.toggle();
  }
  contactus(){
    this.route.navigate(['contact']);
    this.menuCtrl.toggle();
  }

  parks(){ 
    this.route.navigate(['parks']);
    this.menuCtrl.toggle();
  }

  park() {
    if(this.permissions.viewSettings){
      this.route.navigate(['park']);
      this.menuCtrl.toggle();
    } else {
      this.util.presentToast('Permission denied', true, 'bottom', 2100);
      this.menuCtrl.toggle();
    }
  }

  agency(){
    // console.log(this.permissions)
    if(this.permissions.agencyAdmin){
      this.route.navigate(['agency']);
      this.menuCtrl.toggle();
    } else {
      this.util.presentToast('Permission denied', true, 'bottom', 2100);
      this.menuCtrl.toggle();
    }
  }

  person() {
    this.route.navigate(['profile']);
    this.menuCtrl.toggle();
  }

  bookings() {
    this.route.navigate(['bookings']);
    this.menuCtrl.toggle();
  }
  fleet() {
    if (this.agentDetails.permissions.viewFleet){
    this.route.navigate(['fleets']);
    this.menuCtrl.toggle();
    } else {
      this.util.presentToast('Permission denied', true, 'bottom', 2100);
      this.menuCtrl.toggle();
    }
  }
  closed() {
    if (this.agentDetails.permissions.viewClosedTrip || this.agentDetails.permissions.terminalAgent 
      || this.agentDetails.permissions.terminalLoadingAgent
      ){
      this.route.navigate(['closed']);
      this.menuCtrl.toggle();
    } else {
      this.util.presentToast('Permission denied', true, 'bottom', 2100);
      this.menuCtrl.toggle();
    }
  }

  cancelled(){
    if (this.agentDetails.permissions.viewClosedTrip || this.agentDetails.permissions.terminalAgent 
      || this.agentDetails.permissions.terminalLoadingAgent
      ){
      this.route.navigate(['cancelled']);
      this.menuCtrl.toggle();
    } else {
      this.util.presentToast('Permission denied', true, 'bottom', 2100);
      this.menuCtrl.toggle();
    }
  }

  showSidemenu(index) {
    this.sidemenu = index + 1;
  }

  financials(){
    this.route.navigate(['financials']);
    this.menuCtrl.toggle();
  }

  agentFinancials(){
    this.route.navigate(['agent-financials']);
    this.menuCtrl.toggle();
  }

  
  agencyfinancials(){
    if(this.permissions.agencyAdmin){
      this.route.navigate(['agency-transactions']);
      //
      this.menuCtrl.toggle();

    } else {
      this.util.presentToast('Permission denied', true, 'bottom', 2100);
      this.menuCtrl.toggle();
    }

  }

  agentfinancial(){
    //agent-transactions
    this.route.navigate(['agent-transactions']);
    //
    this.menuCtrl.toggle();
  }

 

  expandMenu(title) {
    if (this.showChildren === title) {
      this.showChildren = '';
    } else {
      this.showChildren = title;
    }
    console.log(this.showChildren);
  }

  initializeApp() {
    this.platform.ready().then((data) => {
      // console.log(this.platform.is('capacitor'));
      if (this.platform.is('capacitor')) {
        StatusBar.setStyle({
          style: StatusBarStyle.Dark
        });
        SplashScreen.hide();
      }

      this.util.agentData.subscribe(agentDetails => {
        if (agentDetails) {
          this.agentDetails = agentDetails;
          this.permissions = this.agentDetails.permissions;
          // console.log(this.permissions)
        } else{
          this.route.navigate(['login']);
        }
      });

      this.util.stationData.subscribe(stationData => {
        if(stationData){
          this.stationData = stationData;
          if(stationData.closedTripDays){
            this.util.closedTripDays = stationData.closedTripDays
            this.util.backgroundImage = stationData.logo;
            this.util.backgroundColor = stationData.color;
            // console.log(stationData.stations)
            // console.log(stationData.color)
          }
        }
      });

    });
  }

  
}
