import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { GuardsService } from './guards/guards.service';


const routes: Routes = [
  {

    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  // { path: 'welcome', loadChildren: './pages/welcome/welcome.module#WelcomePageModule', canActivate: [GuardsService] },
  { path: 'welcome', loadChildren: './pages/home/home.module#HomePageModule', canActivate: [GuardsService] },
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule', canActivate: [GuardsService] },
  { path: 'contact', loadChildren: './pages/contactus/contactus.module#ContactPageModule', canActivate: [GuardsService] },

  { path: 'closed', loadChildren: './pages/closed/closed.module#ClosedPageModule', canActivate: [GuardsService]  },

  { path: 'cancelled', loadChildren: './pages/cancelled/cancelled.module#CancelledPageModule', canActivate: [GuardsService]  },
  { path: 'fleets', loadChildren: './pages/fleets/fleets.module#FleetsPageModule', canActivate: [GuardsService]  },
  { path: 'fleet', loadChildren: './pages/fleet/fleet.module#FleetPageModule', canActivate: [GuardsService]  },

  { path: 'financials', loadChildren: './pages/financials/financials.module#FinancialsPageModule', canActivate: [GuardsService]  },
  { path: 'agent-financials', loadChildren: './pages/agent-finance/agent-financials.module#AgentFinancialsPageModule', canActivate: [GuardsService]  },

  { path: 'stationmanager', loadChildren: './pages/stationmanager/stationmanager.module#StationManagerPageModule' , canActivate: [GuardsService]},
  { path: 'booking', loadChildren: './pages/booking/booking.module#BookingPageModule', canActivate: [GuardsService]  },
  { path: 'bookings', loadChildren: './pages/bookings/bookings.module#BookingsPageModule', canActivate: [GuardsService]  },
  {path: 'tripdetails', loadChildren: './pages/tripdetails/tripdetails.module#TripdetailsPageModule', canActivate: [GuardsService]},

  { path: 'profile', loadChildren: './pages/profile/profile.module#ProfilePageModule',  canActivate: [GuardsService]  },
  { path: 'park', loadChildren: './pages/park/park.module#ParkPageModule' , canActivate: [GuardsService]},
  { path: 'agency', loadChildren: './pages/agency/agency.module#AgencyPageModule' , canActivate: [GuardsService]},

  { path: 'agency-transactions', loadChildren: './pages/agencytransactions/agencytransactions.module#AgencyTransactionsPageModule' , canActivate: [GuardsService]},
  { path: 'agent-transactions', loadChildren: './pages/agent-transactions/agent-transactions.module#AgentTransactionsPageModule' , canActivate: [GuardsService]},
  // { path: 'parks', loadChildren: './pages/parks/parks.module#ParksPageModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'addtrip', loadChildren: './pages/addtrip/addtrip.module#AddtripPageModule' , canActivate: [GuardsService]},  //To be taken out for admin/park
  { path: 'addfleet', loadChildren: './pages/addfleet/addfleet.module#AddfleetPageModule' , canActivate: [GuardsService]},  //To be taken out for admin/park
  { path: 'addvehicletype', loadChildren: './pages/addvehicletype/addvehicletype.module#AddVehicleTypePageModule' , canActivate: [GuardsService]},

  { path: 'signup', loadChildren: './pages/signup/signup.module#SignupPageModule' },
  { path: 'addmanager', loadChildren: './pages/addmanager/addmanager.module#AddmanagerPageModule', canActivate: [GuardsService] },
  { path: 'agent', loadChildren: './pages/agent/agent.module#AgentPageModule', canActivate: [GuardsService] },

  { path: 'add-driver', loadChildren: './pages/add-driver/add-driver.module#AddDriverPageModule', canActivate: [GuardsService] },
  
 

 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
