import { Directive, HostListener, Input, Output, HostBinding } from '@angular/core';

@Directive({
  selector: '[appHighlighted]'
})
export class HighlightedDirective {

  @Input('appHighlighted')
    isHighlighted = false;

  // @Output()
  //   toggleHighlight;

  constructor() {
    // console.log('Directive Called');
   }


  @HostBinding('class.highlighted')
    get cssClasses() {
        return this.isHighlighted;
    }

  @HostListener('mouseover')
    mouseOver() {

        // this.toggleHighlight.emit(this.isHighlighted);
        this.isHighlighted = true;
  }

  @HostListener('mouseleave')
    mouseLeave() {
        // this.toggleHighlight.emit(this.isHighlighted);
        this.isHighlighted = false;
  }

}
