
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { FirestoreService } from '../firestore/firestore.service';
import { STATIONMANAGERDto } from 'src/app/models/stationmanager.model';
import { environment } from '../../../environments/environment';


@Injectable()
export class STATIONMANAGERDataService extends BaseDataService<STATIONMANAGERDto> {
    constructor(private firestore: FirestoreService) {
        super('stationmanagers');
    }
    public get(): Observable<STATIONMANAGERDto[]> {
        return this.firestore.get<STATIONMANAGERDto>(environment.dbPrefix + this.baseCollection);
    }

    public getOne(id: string): Observable<STATIONMANAGERDto> {
        return this.firestore.getOne<STATIONMANAGERDto>(environment.dbPrefix + this.baseCollection, id);
    }

    public getAll(): Observable<STATIONMANAGERDto[]> {
        return this.firestore.getAll<STATIONMANAGERDto>(environment.dbPrefix + this.baseCollection);
    }

    public getApproved(): Observable<STATIONMANAGERDto[]> {
        return this.firestore.getApproved<STATIONMANAGERDto>(environment.dbPrefix + this.baseCollection);
    }

    public update(data: Partial<STATIONMANAGERDto>): Promise<void> {
        return this.firestore.update<STATIONMANAGERDto>(environment.dbPrefix + this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(environment.dbPrefix + this.baseCollection, id);
    }

    public create(data: STATIONMANAGERDto): Promise<void> {
        return this.firestore.create(environment.dbPrefix + this.baseCollection, data);
    }

    public runQueryR(query): Observable<STATIONMANAGERDto[]> {
        return this.firestore.runQueryR(environment.dbPrefix + this.baseCollection, query);
    }
}
