
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { FirestoreService } from '../firestore/firestore.service';
import { AGENTDto } from 'src/app/models/agent.model';
import { environment } from '../../../environments/environment';


@Injectable()
export class AgentDataService extends BaseDataService<AGENTDto> {
    constructor(private firestore: FirestoreService) {
        super('agent');
    }

    public get(): Observable<AGENTDto[]> {
        return this.firestore.get<AGENTDto>(this.baseCollection);
    }

    public getOne(id: string): Observable<AGENTDto> {
        return this.firestore.getOne<AGENTDto>(this.baseCollection, id);
    }

    public getAll(): Observable<AGENTDto[]> {
        return this.firestore.getAll<AGENTDto>(this.baseCollection);
    }

    public getApproved(): Observable<AGENTDto[]> {
        return this.firestore.getApproved<AGENTDto>(this.baseCollection);
    }

    public update(data: Partial<AGENTDto>): Promise<void> {
        return this.firestore.update<AGENTDto>(this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(this.baseCollection, id);
    }

    public create(data: AGENTDto): Promise<void> {
        return this.firestore.create(this.baseCollection, data);
    }
    public runQueryR(query): Observable<any[]> {
        return this.firestore.runQueryR(environment.dbPrefix + this.baseCollection, query);
    }
}
