


import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController, NavController, AlertController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import Swal from 'sweetalert2';
import { TRIPDto, TRIPModel } from 'src/app/models/trip.model';

import 'firebase/auth';
import { STATIONMANAGERDto } from 'src/app/models/stationmanager.model';
import { STATIONDto } from 'src/app/models/station.model';
import { BOOKINGDto } from 'src/app/models/booking.model';



// declare var Swal: any;


@Injectable({
  providedIn: 'root'
})
export class UtilService {

  userid: BehaviorSubject<string> = new BehaviorSubject<string>('');
  agentData: BehaviorSubject<STATIONMANAGERDto> = new BehaviorSubject<STATIONMANAGERDto>(null);
  stationData: BehaviorSubject<STATIONDto> = new BehaviorSubject<STATIONDto>(null);
  agencyData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constants: BehaviorSubject<{}> = new BehaviorSubject<{}>({});
  banksArray: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  walletHistory: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  agentWalletHistory: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  transactionHistory: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  vehicleTypes: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  transactions: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  drivers: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  currentTrip: BehaviorSubject<{}> = new BehaviorSubject<{}>({});
  allTrips: BehaviorSubject<TRIPDto[]> = new BehaviorSubject<TRIPDto[]>([]);

  managerOfficePayment: BehaviorSubject<BOOKINGDto[]> = new BehaviorSubject<BOOKINGDto[]>([]);
  allManagerOfficePayment: BehaviorSubject<BOOKINGDto[]> = new BehaviorSubject<BOOKINGDto[]>([]);
  allAgencyPayment: BehaviorSubject<BOOKINGDto[]> = new BehaviorSubject<BOOKINGDto[]>([]);

  onlinePayments: BehaviorSubject<BOOKINGDto[]> = new BehaviorSubject<BOOKINGDto[]>([]);
  transferPayments: BehaviorSubject<BOOKINGDto[]> = new BehaviorSubject<BOOKINGDto[]>([]);
  socialUser: BehaviorSubject<{}> = new BehaviorSubject<{}>(null);

  vehicleTypeEdit;

  states;
  lgas;
  cities;
  parkData;
  bookingData;
  adverts;
  agentDetails: STATIONMANAGERDto;
  stationDetails;  // : STATIONDto;
  agencyDetail;
  appUpp = false;
  accessToken;
  uid;
  banks;
  displayManager;
  displayDriver;
  displayAgent;
  flutterwaveKey;
  flutterwaveLive;
  fleets;

  trips: Array<TRIPDto> = [];
  public tripList: Array<TRIPDto>;
  editParkData: STATIONDto;
  openedTrip: TRIPDto;
  manifestCutOffDays = 0;
  platformConstants;
  closedTrip = false;
  notificationArray ;
  public analytics = firebase.analytics();
  allowBooking = false;
  openedFleet;
  openedFleet2;
  public closedTripList: Array<TRIPDto> = [];

  public bluetoothPrinterConnected = false;
  public closedTripDays = 3;
  backgroundImage;
  backgroundColor;

  userType;
  subTitle = null;
  constructor(
    private http: HttpClient,
    public loadingController: LoadingController,
    private fireAuth: AngularFireAuth,
    public fireStore: AngularFirestore,
    private router: Router,
    private toastController: ToastController,
    private nav: NavController, private afMessaging: AngularFireMessaging,

    public alertController: AlertController) {
    this.getUserId();
    this.getStates();
    this.getLga();
    this.getCities();
    this.getConstants_();
    this.getFlutterwaveBank();
    this.getVehicleType();
    // this.getBanks();
    //this.getAdverts();

    // Bind methods to fix temporary bug in AngularFire
    try {
      const messaging = firebase.messaging();
      messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
      messaging.onMessage = messaging.onMessage.bind(messaging);
    } catch (e) {

    }
  }

  public logEvent(eventName, eventParams) {
    this.analytics.logEvent(eventName, eventParams);
  }

  getOfficeBookingPerManager() {
    // console.log('Get office booking',this.userType);
    const bookingCollection = environment.dbPrefix + 'booking' ;
    // const bookigCollection = 'prod_booking' ;
    try {
      const last48Hours = Date.now()-8*24*60*60*1000

      this.fireStore.collection<BOOKINGDto>(bookingCollection, ref => ref.where('originStation', '==', this.agentDetails.location)
      .where('officeCollectorId', '==', this.agentDetails.id).where('paymentDate','>=',last48Hours)).valueChanges().subscribe(officePayment => {
      // console.log('Office Payment',officePayment);
      this.managerOfficePayment.next(officePayment.sort((b, a) => a.paymentDate <= b.paymentDate ? -1 : 1));
      });

    }  catch (e) {
      // console.log('get office booking error - ', e);
    }

  }

  // getBookingPerAgent(){
  //   const bookingCollection = environment.dbPrefix + 'booking' ;
  //   try {
  //     const last48Hours = Date.now()-8*24*60*60*1000

  //     this.fireStore.collection<BOOKINGDto>(bookingCollection, ref => ref.where('originStation', '==', this.agentDetails.location)
  //     .where('officeCollectorId', '==', this.agentDetails.id).where('paymentDate','>=',last48Hours)).valueChanges().subscribe(officePayment => {
  //     // console.log('Office Payment',officePayment);
  //     this.managerOfficePayment.next(officePayment.sort((b, a) => a.paymentDate <= b.paymentDate ? -1 : 1));
  //     });

  //   }  catch (e) {
  //     console.log('get office booking error - ', e);
  //   }
  // }

  getOnlineBookings() {
    // console.log('Get all office booking');
    const bookingCollection = environment.dbPrefix + 'booking' ;
   
    try {
      const last48Hours = Date.now()-8*24*60*60*1000
      console.log('Check online')
      this.fireStore.collection<BOOKINGDto>(bookingCollection, ref => ref.where('originStation', '==', this.agentDetails.location)
        // .where('paymentMode', 'in', ['Payment Gateway','Account Transfer'])
        .where('paymentMode', '==', 'Payment Gateway')
        .where('paymentDate','>=',last48Hours)).valueChanges().subscribe(onlinePayment => {

      this.onlinePayments.next(onlinePayment.sort((b, a) => a.paymentDate <= b.paymentDate ? -1 : 1));
      // console.log('Online Payments', onlinePayment);
      });

    }  catch (e) {
      console.log('get office booking error - ', e);
    }
  }

  getTransferBookings() {
    // console.log('Get all office booking');
    const bookingCollection = environment.dbPrefix + 'booking' ;
   
    try {
      const last48Hours = Date.now()-8*24*60*60*1000
      console.log('Check online')
      this.fireStore.collection<BOOKINGDto>(bookingCollection, ref => ref.where('originStation', '==', this.agentDetails.location)
        // .where('paymentMode', 'in', ['Payment Gateway','Account Transfer'])
        .where('paymentMode', '==', 'Account Transfer')
        .where('paymentDate','>=',last48Hours)).valueChanges().subscribe(onlinePayment => {

      this.transferPayments.next(onlinePayment.sort((b, a) => a.paymentDate <= b.paymentDate ? -1 : 1));
      // console.log('Online Payments', onlinePayment);
      });

    }  catch (e) {
      console.log('get office booking error - ', e);
    }
  }

  getAllOfficeBooking() {
    // console.log('Get all office booking');
    const last48Hours = Date.now()-8*24*60*60*1000

    const bookigCollection = environment.dbPrefix + 'booking' ;
    try {
      this.fireStore.collection<BOOKINGDto>(bookigCollection, ref => ref.where('originStation', '==', this.agentDetails.location)
      .where('officePayment', '==', true).where('paymentDate','>=',last48Hours)).valueChanges().subscribe(allOfficePayment => {
      allOfficePayment.sort((b, a) => a.paymentDate <= b.paymentDate ? -1 : 1);
      this.allManagerOfficePayment.next(allOfficePayment);
      // console.log('All Manager Payment', allOfficePayment)
      });

    }  catch (e) {
      console.log('get office booking error - ', e);
    }
  }

  getAllAgencyBooking(){
    const last48Hours = Date.now()-8*24*60*60*1000
    const bookigCollection = environment.dbPrefix + 'booking' ;
    try {
      this.fireStore.collection<BOOKINGDto>(bookigCollection, ref => ref.where('agencyId', '==', this.agentDetails['agencyId'])
      .where('agencyPayment', '==', true).where('paymentDate','>=',last48Hours)).valueChanges().subscribe(allOfficePayment => {
      allOfficePayment.sort((b, a) => a.paymentDate <= b.paymentDate ? -1 : 1);
      this.allAgencyPayment.next(allOfficePayment);
      // console.log('All Manager Payment', allOfficePayment)
      });

    }  catch (e) {
      console.log('get office booking error - ', e);
    }

  }

  getStationAgencyBooking(){
    const last48Hours = Date.now()-8*24*60*60*1000
    const bookigCollection = environment.dbPrefix + 'booking' ;
    try { 
      // console.log('stationId', '==', this.agentDetails.stationId)
      this.fireStore.collection<BOOKINGDto>(bookigCollection, ref => ref.where('stationId', '==', this.agentDetails.stationId)
      .where('agencyPayment', '==', true).where('paymentDate','>=',last48Hours)).valueChanges().subscribe(allOfficePayment => {
      allOfficePayment.sort((b, a) => a.paymentDate <= b.paymentDate ? -1 : 1);
      this.allAgencyPayment.next(allOfficePayment);
      // console.log('All Agency Payment', allOfficePayment)
      });

    }  catch (e) {
      console.log('get office booking error - ', e);
    }

  }

  getBookingPerAgent(){
    const bookingCollection = environment.dbPrefix + 'booking' ;
    try {
      const last48Hours = Date.now()-8*24*60*60*1000

      this.fireStore.collection<BOOKINGDto>(bookingCollection, ref => ref.where('agencyId', '==', this.agentDetails['agencyId'])
      .where('agentId', '==', this.agentDetails.id).where('paymentDate','>=',last48Hours)).valueChanges().subscribe(officePayment => {
      // console.log('Office Payment',officePayment);
      this.managerOfficePayment.next(officePayment.sort((b, a) => a.paymentDate <= b.paymentDate ? -1 : 1));
      });

    }  catch (e) {
      console.log('get office booking error - ', e);
    }
  }

  public addVehicleHistory(vehicleData ,accessToken ) {
    vehicleData.env = environment.dbPrefix
    const myUrl = environment.containerurl + 'station/driver-vehicle-update';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.post(myUrl, vehicleData, {headers});
  }

  public addVehicleExpense(expenseData ,accessToken ) {
    expenseData.env = environment.dbPrefix
    const myUrl = environment.containerurl + 'fleet-manager/fleet-expense';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.post(myUrl, expenseData, {headers});
  }

  public deleteTrip(tripData,accessToken){
    tripData.env = environment.dbPrefix
    const myUrl = environment.containerurl + 'station/trip';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.patch(myUrl, tripData, {headers});
  }

  public cancelBooking(bookingData,accessToken){
    bookingData.env = environment.dbPrefix
    const myUrl = environment.containerurl + 'station/booking';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.patch(myUrl, bookingData, {headers});
  }

  public openClosedTrip(tripData,accessToken){
    tripData.env = environment.dbPrefix
    const myUrl = environment.containerurl + 'station/opentrip';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.patch(myUrl, tripData, {headers});
  }

  public deleteBooking(bookingData,accessToken){
    bookingData.env = environment.dbPrefix
    const myUrl = environment.containerurl + 'agent/booking';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.patch(myUrl, bookingData, {headers});
  }

   getUserId() {
    this.fireAuth.auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.uid = user.uid;
        this.userid.next(user.uid);
        this.accessToken = await firebase.auth().currentUser.getIdToken();
        this.getAgentData();
        
      }
    });
  }

  getAccountingData() {
    const accountCollection = environment.dbPrefix + 'account_transactions' ;
    // console.log(accountCollection);
    try {
      const last48Hours = Date.now()-8*24*60*60*1000

      this.fireStore.collection(accountCollection, ref => ref.where('originStation', '==', this.agentDetails.location).where('transactionDate','>=',last48Hours))
      .valueChanges().subscribe(acct => {
        this.transactions.next(acct);
        // console.log(acct)
      });

    } catch (e) {
      console.log('get account error - ', e);
    }
  }


  getBanks() {
    const myUrl = environment.cloudfunctionurl + '/getFlutterwaveBanks';
    return this.http.get(myUrl).subscribe(banks => {
      console.log(banks);
    });

  }

  getAgentData() {
    // console.log(this.userType)
    if(this.userType === 'stationManager'){

      const stationManagerCollection = environment.dbPrefix + 'stationmanagers' ;
      try {
        this.fireStore.collection(stationManagerCollection).doc<STATIONMANAGERDto>(this.uid).valueChanges().subscribe(agentData => {
          if (agentData) {
            this.agentData.next(agentData);
            this.agentDetails = agentData;
            this.getStationData();
            // console.log('here', agentData)
            this.getOfficeBookingPerManager();
            this.getVehicleType();
            const viewFinancials = 'viewFinancials';
            if (agentData.permissions[viewFinancials]) {
              // this.getAccountingData();
              this.getAllOfficeBooking();
              this.getOnlineBookings();
              this.getTransferBookings();
            }
          }
        });
    } catch (e) {
      console.log('get data error 1 - ', e);
    }
    } else if(this.userType === 'ticketingAgent'){
      const agentCollection = environment.dbPrefix + 'agent' ;
      try {
        this.fireStore.collection(agentCollection).doc<any>(this.uid).valueChanges().subscribe(agentData => {
          if (agentData) {
            this.agentData.next(agentData);
            this.agentDetails = agentData;
          }
       });
      } catch (e) {
        console.log('get data error 1 - ', e);
      }

    }
    
  }
  getAgencyData(agencyId){
    if (agencyId) {
      const agencyCollection = environment.dbPrefix + 'agency' ;
      try {
        this.fireStore.collection(agencyCollection).doc(agencyId).valueChanges().subscribe(agencyData => {
          this.agencyData.next(agencyData);
          this.agencyDetail = agencyData;
          // console.log('station', stationData)
        });
      } catch (e) {
        console.log('get station error 1 -', e);
      }
    }
  }

  getStationData() {
    // console.log('station',this.agentDetails)
    if (this.agentDetails.stationId) {
      const stationCollection = environment.dbPrefix + 'station' ;
      try {
        this.fireStore.collection(stationCollection).doc<STATIONDto>(this.agentDetails.stationId).valueChanges().subscribe(stationData => {
          if(stationData){
            this.stationData.next(stationData);
            this.stationDetails = stationData;
          }
         
          // console.log('station', stationData)
        });
      } catch (e) {
        console.log('get station error 1 -', e);
      }
    }
  }

  updateUser(userData) {
    const userCollection = environment.dbPrefix + 'stationmanagers';
    this.fireStore.collection(userCollection).doc<STATIONMANAGERDto>(userData.id).set(userData, { merge: true}).then(() => {
        // console.log('Token updated');
        // this.confirmDialogPassingparameter('Created', userData.username + ' is now an Admin. Kindly grant necessary permission')
      });
  }

  updateAgencyWallet(walletDetails,accessToken){
    walletDetails.env= environment.dbPrefix 
    const myUrl = environment.containerurl  + 'agency/wallet';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.post(myUrl, walletDetails, {headers});
  }

  agentTransfer(walletDetails,accessToken){
    walletDetails.env= environment.dbPrefix 
    const myUrl = environment.containerurl  + 'agency/transfer';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.post(myUrl, walletDetails, {headers});
  }

  agentRefund(walletDetails,accessToken){
    walletDetails.env= environment.dbPrefix 
    const myUrl = environment.containerurl  + 'agency/agent2agency';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.post(myUrl, walletDetails, {headers});
  }

  updateNotificationToken(newToken) {

    if (this.agentDetails.notificationArray) {
      this.notificationArray = this.agentDetails.notificationArray;
      const existing = this.notificationArray.find(token => token === newToken);
      if (existing) {
        return;
      } else {
        this.notificationArray.push(newToken);
        this.agentDetails.notificationArray = this.notificationArray;
        this.updateUser(this.agentDetails);
      }
    } else {
      // this.notificationArray.push(newToken);
      const notificationArray = [newToken];
      this.agentDetails.notificationArray = newToken;
      this.updateUser(this.agentDetails);
    }
  }



  getStates() {
    const url = environment.config.databaseURL + '/station/states.json';
    return this.http.get(url).subscribe(states => {
      this.states = states;
    });
  }

  addVehicleType(vehicle) {
    const vehicleTypeCollection = environment.dbPrefix + 'vehicleType';
    vehicle.stationId = this.agentDetails.stationId;
    vehicle.enrolledDate = Date.now();
    vehicle.log = [{
      date: Date.now(),
      action: 'created',
      userId: this.agentDetails.id,
      userName: this.agentDetails.username
    }];
    return this.fireStore.collection(vehicleTypeCollection).doc(vehicle.id).set(vehicle, { merge: true});
  }

  getVehicleType() {
    const vehicleTypeCollection = environment.dbPrefix + 'vehicleType';
    try {
      this.fireStore.collection(vehicleTypeCollection, ref => ref.where('stationId', '==', `${this.agentDetails.stationId}`)).valueChanges().subscribe(vehicleType => {
        if (vehicleType) {
          this.vehicleTypes.next(vehicleType);
        }
      });
    } catch (e) {
      // console.log('get vehicleType error - ', e);
    }
  }

  getDrivers() {
    // console.log('Search driver')
    const drivereCollection = environment.dbPrefix + 'stationdrivers';
    try {
      this.fireStore.collection(drivereCollection, ref => ref.where('stationId', '==', `${this.agentDetails.stationId}`)).valueChanges().subscribe(drivers => {
        if (drivers) {
          // console.log('Search driver'), drivers
          this.drivers.next(drivers);
        }
      });
    } catch (e) {
      console.log('get driver error - ', e);
    }
  }

  getAdverts() {
    const url = environment.config.databaseURL + '/station/adverts.json';
    return this.http.get(url).subscribe(adverts => {
      this.adverts = adverts;
    });

  }
  getLga() {
    const url = environment.config.databaseURL + '/station/localgovernment_ng.json';
    return this.http.get(url).subscribe(lgas => {
      this.lgas = lgas;
    });

  }

  // getConstants() {
  //   return this.http.get('https://arrive-ng.firebaseio.com/station/important_notice.json')
  // }
  getConstants_() {
    const constantCollection = environment.dbPrefix + 'constants' ;
    try {
      this.fireStore.collection(constantCollection).valueChanges().subscribe(constants => {
        if (constants) {
          this.constants.next(constants[0]);
          this.platformConstants = constants[0];
        }
      });
    } catch (e) {
      console.log('get constant error - ', e);
    }
  }

  sendContactUs(messageObj) {
    const contactCollection = environment.dbPrefix + 'contactus' ;
    return this.fireStore.collection(contactCollection).doc(messageObj.id).set(messageObj);
  }

  updateStationContactUs(messageObj){
    const contactCollection = environment.dbPrefix + 'contactus' ;
    return this.fireStore.collection(contactCollection).doc(messageObj.id).update(messageObj);
  }

  getContactUs() {
    const contactCollection = environment.dbPrefix + 'contactus' ;
    return this.fireStore.collection(contactCollection, ref => ref.where('clientId', '==', `${this.uid}`)).valueChanges();
  }

  getCurrentTrip(tripId){
    const tripCollection = environment.dbPrefix + 'trip' ;
    return this.fireStore.collection(tripCollection).doc(tripId).valueChanges()
  }

  getWalletHistory(){
    try {
      const contactCollection = environment.dbPrefix + 'agentWallet' ;
      this.fireStore.collection(contactCollection, ref => ref.where('agencyId', '==', this.agentDetails['agencyId'])).valueChanges().subscribe(walletHistory => {
        this.walletHistory.next(walletHistory);
      });
    } catch (e) {
      console.log('get bank error - ', e);
    }
  }

  getAgentWalletHistory(){
    try {
      const contactCollection = environment.dbPrefix + 'agencyAccount' ;
      this.fireStore.collection(contactCollection, ref => ref.where('agentId', '==', this.agentDetails['id']).orderBy('approvalDate')
      ).valueChanges().subscribe(walletHistory => {
        // console.log(walletHistory,this.agentDetails['id'])
        this.agentWalletHistory.next(walletHistory);
      });
    } catch (e) {
      console.log('get bank error - ', e);
    }
  }

  getTransactionHistory(){
    try {
      const contactCollection = environment.dbPrefix + 'agencyAccount' ;
      this.fireStore.collection(contactCollection, ref => ref.where('agencyId', '==', this.agentDetails['agencyId'])).valueChanges().subscribe(transactionHistory => {
        this.transactionHistory.next(transactionHistory);
      });

    } catch (e) {
      console.log('transaction error - ', e);
    }
  }

  getFlutterwaveBank() {
    try {
      this.fireStore.collection('flutterwaveBanks').valueChanges().subscribe(banks => {
        this.banksArray.next(banks);
      });
    } catch (e) {
      console.log('get bank error - ', e);
    }
  }

  getCities() {
    const url = environment.config.databaseURL + '/station/city_table.json';
    return this.http.get(url).subscribe(cities => {
      this.cities = cities;
    });

  }

  


  navigate(link, forward?) {
    // if (forward) {
    //   this.nav.navigateForward('/' + link);
    // } else {
    //   this.router.navigateByUrl('/' + link);
    // }
    this.nav.navigateForward('/' + link);
  }

  navigateBack(){
    this.nav.back();
  }

  navigateForward(link, type?) {
    if (type) {
      this.router.navigate([link], {queryParams: { type}});
    } else {
      this.router.navigate([link]);
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  async presentToast(message, showButton, position, duration) {
    const toast = await this.toastController.create({
      message,
     //showCloseButton: showButton,
     buttons: [
      {
        text: 'Close',
        role: 'cancel',
        handler: () => {
          console.log('Close clicked');
        }
      }
    ],
      position,
      duration
    });
    toast.present();
  }


  removeConform(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: 'Confirm!',
        message: 'Are you sure you want to remove this item',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (cancel) => {

              resolve('cancel');
            }
          }, {
            text: 'Confirm',
            handler: (ok) => {

              resolve('ok');
            }
          }
        ]
      });

      alert.present();
    });
  }

  confirmationMessage(title, message): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: title,
        message: message,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (cancel) => {

              resolve('cancel');
            }
          }, {
            text: 'Confirm',
            handler: (ok) => {

              resolve('ok');
            }
          }
        ]
      });

      alert.present();
    });
  }

  async alertMessage(title, message){
      const alert = await this.alertController.create({
        header: title,
        message: message,
        buttons: [{
            text: 'OK',
            handler: (ok) => {

              // console.log('ok');
            }
          }
        ]
      });

      alert.present();
  
  }

  async openLoader() {
    const loading = await this.loadingController.create({
      message: 'Please Wait ...',
      duration: 2000
    });
    await loading.present();
  }
  async openInfLoader() {
    const loading = await this.loadingController.create({
      message: 'Please Wait ...'
    });
    await loading.present();
  }
  async closeLoading() {
    return await this.loadingController.dismiss();
  }

  async getBlob(webPath: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const blob = await fetch(webPath).then(r => r.blob());
        console.log('blob', blob);
        resolve(blob);
      } catch (e) {
        console.error('Unable to make blob', e);
        reject(e);
      }
    });
  }

  // Sample API response for content loading
  getApiResponse() {
    const myUrl = 'https://www.mocky.io/v2/5c9215a73200005d006bccee?mocky-delay=5000ms';
    return this.http.get(myUrl);
  }
  // Sample API response for Infinite scrolling
  infiniteData() {
    const myUrl = 'https://www.mocky.io/v2/5c9448a0310000a45b55487c?mocky-delay=5000ms';
    return this.http.get(myUrl);
  }
  getTokenPermission()  {
    return  this.afMessaging.requestToken;
  }

  cashPayment(bookingData ,accessToken) {
   const myUrl = environment.cloudfunctionurl + '/' + environment.dbPrefix + 'cashBooking';
   const headers = { Authorization: 'Bearer ' + accessToken};
   return this.http.post(myUrl, bookingData, {headers});
  }

  getVirtualAccount(bookingData ,accessToken) {
    const myUrl = environment.containerurl + 'account/create-dynamic';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.post(myUrl, bookingData, {headers});
   }

  agentCashPayment(bookingData ,accessToken) {
    const myUrl = environment.containerurl + 'agent/booking';
    const data = {
      env: environment.dbPrefix,
      bookingData
    }
    const headers = { Authorization: 'Bearer ' + accessToken};
    // console.log(data)
    // return 
    return this.http.post(myUrl, data, {headers});
   }

  createBooking(bookingData ,accessToken) {
    // const myUrl = environment.cloudfunctionurl + '/' + environment.dbPrefix + 'createBooking';
    const myUrl = environment.containerurl  + 'agent/online-booking';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.post(myUrl, bookingData, {headers});
   }

  bookingUpdate(tripData, bookingIds,accessToken) {
    const changeBody = {
      env: environment.dbPrefix,
      driverName  : tripData.driverName,
      vehicleReg: tripData.vehicleReg,
      totalPassengers: tripData.totalPassengers,
      vehicleModel : tripData.vehicleType.vehicleModel,
      vehicleType : tripData.vehicleType,
      bookingIds

    };
    const myUrl = environment.cloudfunctionurl + '/' + environment.dbPrefix + 'bookingUpdate';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.post(myUrl, changeBody, {headers});
   }

   monitorAccount(paymentId){
    //this.paymentId
    return this.fireStore.collection('virtual_account_booking').doc<any>(paymentId).valueChanges()
   }


  confirmDialogPassingparameter(title, text) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
     //   cancelButton: 'btn btn-danger'
      },
      buttonsStyling: true,
      heightAuto: false,
      confirmButtonColor: '#4d9301',
    });

    swalWithBootstrapButtons.fire({
      title,
      text,
      confirmButtonText: 'Close',
      confirmButtonColor: '#4d9301',
      reverseButtons: false,
      heightAuto: false
    }).then(() => {

    });
  }

  accessDeniedNotification(message) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
      },
      buttonsStyling: true,
      heightAuto: false
    });
    swalWithBootstrapButtons.fire({
      title: 'Access Denied',
      text: message,
      confirmButtonText: 'OK!',
      confirmButtonColor: '#eb445a',

      reverseButtons: true,
      heightAuto: false
    }).then((result) => {
    });
  }

  addAudit(data) {
    const audittrailCollection = environment.dbPrefix + 'audittrail';
    return this.fireStore.collection(audittrailCollection).doc(data.id).set(data, { merge: true});
  }

}
