
import { BaseDatabaseModel } from '../../models/base-dto.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { UtilService } from '../util/util.service';
import { map } from 'rxjs/operators';

@Injectable()
export class FirestoreService {
    userid: any;

    constructor(public store: AngularFirestore, private fireAuth: AngularFireAuth, private util: UtilService) {
        this.util.userid.subscribe(res => {
            this.userid = res;
        });
    }

    public create<T extends BaseDatabaseModel>(collection: string, data: T): Promise<void> {
        return this.store.doc<T>(`${collection}/${data.id}`).set(data, {merge: true});
    }

    public get<T extends BaseDatabaseModel>(collection: string): Observable<T[]> {
        return this.store.collection<T>(collection, ref => ref.where('uid', '==', `${this.userid}`)).valueChanges();
    }

    public getAll<T extends BaseDatabaseModel>(collection: string): Observable<T[]> {
        return this.store.collection<T>(collection).valueChanges(); // , ref => ref.where('uid', '==', `${this.userid}`)
    }

    public getApproved<T extends BaseDatabaseModel>(collection: string): Observable<T[]> {
        return this.store.collection<T>(collection, ref => ref.where('usersid', 'array-contains', `${this.userid}`)).valueChanges(); // , ref => ref.where('uid', '==', `${this.userid}`)
    }

    public getOne<T extends BaseDatabaseModel>(collection: string, id: string): Observable<T> {
        return this.store.doc<T>(`${collection}/${id}`).valueChanges();
    }

    public update<T extends BaseDatabaseModel>(collection: string, id: string, document: Partial<T>): Promise<void> {
        return this.store.doc<T>(`${collection}/${id}`).update(document);
    }

    public runQuery<T extends BaseDatabaseModel>(collection: string, query: FirestoreQuery): Observable<T[]> {
        return this.store.collection<T>(collection, ref => ref.where(query.field, query.operation, query.searchKey)).valueChanges();
    }
    public runQueryR<T extends BaseDatabaseModel>(collection: string, query, limited?): Observable<T[]> {
        // console.log('closed Trip')
        if (limited){
            const last48Hours = Date.now()-this.util.closedTripDays*24*60*60*1000
            const loadingAgent = this.util.agentDetails['permissions']['terminalLoadingAgent'] || this.util.agentDetails['permissions']['terminalAgent']
            const viewClosedTrip = this.util.agentDetails['permissions']['viewClosedTrip'] || this.util.agentDetails['permissions']['viewTripDetails'] || this.util.agentDetails['permissions']['superAdmin']
            const terminal =  this.util.agentDetails['terminalAddress']
            console.log(terminal)
            if(viewClosedTrip){
                return this.store.collection<T>(collection, ref => ref.where(query.field, query.operation, query.searchKey).where('tripDate','>=',last48Hours)).valueChanges();
            } else if(loadingAgent){
                return this.store.collection<T>(collection, ref => ref.where(query.field, query.operation, query.searchKey).where('tripDate','>=',last48Hours).where('originStationAddress','==',terminal)).valueChanges();
            }

            
        } else {
            return this.store.collection<T>(collection, ref => ref.where(query.field, query.operation, query.searchKey)).valueChanges();
        }
    }

    // public runQueryT<T extends BaseDatabaseModel>(collection: string, query, limited?): Observable<T[]> {

    // }

    public delete<T extends BaseDatabaseModel>(collection: string, id: string): Promise<any> {
        return this.store.doc<T>(`${collection}/${id}`).delete();
    }

    public uploadFile(folderName: string, downloadUrl: string, fileName: string): Promise<any> {
        return this.store.collection<{ downloadUrl: string, fileName: string, uid: string }>(`fileReferences`).add({ downloadUrl, fileName, uid: this.userid });
    }
    public getImages(): Observable<any> {
        return this.store.collection('fileReferences', ref => ref.where('uid', '==', `${this.userid}`)).snapshotChanges().pipe(map(actions => {
            return actions.map(a => {
                const data: any = a.payload.doc.data();
                data.id = a.payload.doc.id;
                return data;
            });
        }));
    }
}

export interface FirestoreQuery {
    field: string;
    operation: firebase.firestore.WhereFilterOp;
    searchKey: string;
}
