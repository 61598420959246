
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import {STATIONDto} from '../../models/station.model';
import { FirestoreService } from '../firestore/firestore.service';
import { UtilService } from '../util/util.service';
import { environment } from '../../../environments/environment';



@Injectable()
export class STATIONDataService extends BaseDataService<STATIONDto> {
    constructor(private firestore: FirestoreService, private util: UtilService) {
        super('station');
    }

    public get(): Observable<STATIONDto[]> {
        return this.firestore.get<STATIONDto>(environment.dbPrefix + this.baseCollection);
    }
    public getAll(): Observable<STATIONDto[]> {
        return this.firestore.getAll<STATIONDto>(environment.dbPrefix + this.baseCollection);
    }

    public getApproved(): Observable<STATIONDto[]> {
        return this.firestore.getApproved<STATIONDto>(environment.dbPrefix + this.baseCollection);
    }

    public getOne(id: string): Observable<STATIONDto> {
        return this.firestore.getOne<STATIONDto>(environment.dbPrefix + this.baseCollection, id);
    }

    public update(data: Partial<STATIONDto>): Promise<void> {
        // console.log(environment.dbPrefix + this.baseCollection, data.id, data);
        return this.firestore.update<STATIONDto>(environment.dbPrefix + this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(environment.dbPrefix + this.baseCollection, id);
    }

    public create(data: STATIONDto): Promise<void> {
        return this.firestore.create(environment.dbPrefix + this.baseCollection, data);
    }
}
