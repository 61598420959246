import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from 'src/app/services/util/util.service';
import { AngularCreatePdfService } from 'angular-create-pdf';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-print-page',
  templateUrl: './print-page.page.html',
  styleUrls: ['./print-page.page.scss'],
})
export class PrintPagePage implements OnInit {

 
  @Input() model_title: string;
  @Input() bookings;

  public tripDateTicket = '';
  public tripTimeTicket = '';
  public officeCollectorName = '';


  

  constructor( private util: UtilService, private pdfService: AngularCreatePdfService, private modalController: ModalController,) { 

  }

  ngOnInit() {
    this.tripDateTicket = new Date(this.bookings[0].tripDate).toDateString();
    this.tripTimeTicket = new Date(this.bookings[0].tripDate).toLocaleTimeString();
    this.officeCollectorName = this.util.agentDetails.username;

  }

  public createPdfTem(ele: any) {

    console.log(ele)
    this.pdfService.createPdf(ele, 'Receipt');
  }

  async closeModel() {
    const close: string = "Modal Removed";
    await this.modalController.dismiss(close);
  }

  dateNow(){
    return new Date().toLocaleString();
  }

}
