
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subject, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

import { UtilService } from '../util/util.service';
import * as firebase from 'firebase/app';
import { STATIONMANAGERDataService } from '../data-services/stationmanager-data.service';

export class AuthInfo {

    constructor(public $uid: string) {}

    isLoggedIn() {
        return !!this.$uid;
    }
}

@Injectable()
export class AuthenticationService {
    static UNKNOWN_USER = new AuthInfo(null);
    public authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(AuthenticationService.UNKNOWN_USER);
    public loginProviderId;
    constructor(private fireAuth: AngularFireAuth, private util: UtilService, private stationmanagerDataServ: STATIONMANAGERDataService,private http: HttpClient) {

        this.fireAuth.authState.pipe(
            take(1)
        ).subscribe(user => {
            if (user) {
                this.authInfo$.next(new AuthInfo(user.uid));
            }
        });
    }
    public forgotPassoword(email: string) {
        const myUrl = environment.containerurl + 'auth/password-reset';
        const headers = { Authorization: 'Bearer '};
       return  this.http.post(myUrl, {email:email}, {headers})

        // this.fireAuth.auth.sendPasswordResetEmail(email).then(() => {
        //    this.util.presentToast('Email Sent', true , 'bottom', 2100);
        // }).catch(err => this.util.presentToast(`${err}`, true, 'bottom', 2100));

    }

    public createAccount(email: string, password: string, displayName: string, stationmanager ): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.fireAuth.auth.createUserWithEmailAndPassword(email, password)
                .then(res => {
                    if (res.user) {
                        this.authInfo$.next(new AuthInfo(res.user.uid));
                        this.stationmanagerDataServ.create({
                            email,
                            id: res.user.uid,
                            username: displayName,
                            provider: 'Email Login',
                            usertype: 'stationmanager',
                            phone: stationmanager.phone,
                            location: stationmanager.location,
                            transporter: stationmanager.transporter,
                            nin: stationmanager.nin,
                            ninURL: stationmanager.ninURL,
                            status: 'new',
                            accountName: stationmanager.accountName,
                            accountBank: stationmanager.accountBank,
                            accountNumber: stationmanager.accountNumber,
                            bookedTrips : 0,
                            totalAmountBooked : 0,
                            availableCredit : 0,
                            accountUpdateDate : Date.now(),
                            firstLogin: new Date().toISOString(),
                            notificationArray: []
                          });
                        resolve(res.user);
                    }
                })
                .catch(err => {

                    this.authInfo$.next(AuthenticationService.UNKNOWN_USER);
                    reject(`creation failed ${err}`);
                });
        });
    }

    public addAccount(email: string, password: string, displayName: string, stationmanager ): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.fireAuth.auth.createUserWithEmailAndPassword(email, password)
                .then(res => {
                    if (res.user) {
                        // this.authInfo$.next(new AuthInfo(res.user.uid));
                        this.stationmanagerDataServ.create({
                            email,
                            id: res.user.uid,
                            username: displayName,
                            provider: 'Email Login',
                            usertype: 'stationmanager',
                            phone: stationmanager.phone,
                            location: stationmanager.location,
                            transporter: stationmanager.transporter,
                            nin: stationmanager.nin,
                            ninURL: stationmanager.ninURL,
                            status: 'active',
                            permissions: stationmanager.permissions,
                            accountName: stationmanager.accountName,
                            accountBank: stationmanager.accountBank,
                            accountNumber: stationmanager.accountNumber,
                            bookedTrips : 0,
                            totalAmountBooked : 0,
                            availableCredit : 0,
                            accountUpdateDate : Date.now(),
                            firstLogin: new Date().toISOString(),
                            notificationArray: [],
                            terminal: stationmanager.terminal
                          });
                        resolve(res.user);
                    }
                })
                .catch(err => {

                    this.authInfo$.next(AuthenticationService.UNKNOWN_USER);
                    reject(`creation failed ${err}`);
                });
        });
    }

    public addStationManager(email: string, password: string, displayName: string, stationmanager,accessToken ) {
        const userData = {
            password,
            env:environment.dbPrefix,
            userData: {
                email,
                username: displayName,
                provider: 'Email Login',
                usertype: 'stationmanager',
                phone: stationmanager.phone,
                location: stationmanager.location,
                transporter: stationmanager.transporter,
                nin: stationmanager.nin,
                ninURL: stationmanager.ninURL,
                status: 'active',
                permissions: stationmanager.permissions,
                accountName: stationmanager.accountName,
                accountBank: stationmanager.accountBank,
                accountNumber: stationmanager.accountNumber,
                bookedTrips : 0,
                totalAmountBooked : 0,
                availableCredit : 0,
                accountUpdateDate : Date.now(),
                firstLogin: new Date().toISOString(),
                notificationArray: [],
                terminal: stationmanager.terminal,
                terminalAddress: stationmanager.terminalAddress
              }
        };
//station-manager
        // const myUrl = environment.cloudfunctionurl + '/' + environment.dbPrefix + 'createStationManager';
        const myUrl = environment.containerurl + 'station/station-manager';
        const headers = { Authorization: 'Bearer ' + accessToken};
        return this.http.post(myUrl, userData, {headers});
    }

    public updateManager( stationmanager,accessToken ) {
        const userData = {
            env:environment.dbPrefix,
            userData: stationmanager

        }

        const myUrl = environment.containerurl + 'station/station-manager';
        const headers = { Authorization: 'Bearer ' + accessToken};
        return this.http.patch(myUrl, userData, {headers});
    }

    public addDriver(driverData ,accessToken ) {
        driverData.env = environment.dbPrefix
        const myUrl = environment.containerurl + 'station/driver';
        const headers = { Authorization: 'Bearer ' + accessToken};
        return this.http.post(myUrl, driverData, {headers});
    }

    public addAgent(email: string, password: string, displayName: string, agent,accessToken ) {
        const userData = {
            password,
            fromAdmin:false,
            env: environment.dbPrefix ,
            userData: {
                email,
                username: displayName,
                provider: 'Email Login',
                usertype: 'agent',
                phone: agent.phone,
                nin: agent.nin,
                ninURL: agent.ninURL,
                status: 'active',
                currentLimit:agent.currentLimit,
                availableCredit:agent.availableCredit,
                agency: agent.agency,
                agencyId: agent.agencyId,
                accountUpdateDate : Date.now(),
                firstLogin: new Date().toISOString(),
                notificationArray: [],
                permissions: agent.permissions
              }
        };

        // const myUrl = environment.cloudfunctionurl + '/' + environment.dbPrefix + 'createStationManager';
        const myUrl = environment.containerurl  + 'agency/agent';
        const headers = { Authorization: 'Bearer ' + accessToken};
        return this.http.post(myUrl, userData, {headers});
    }

    public updateAgent( agent,accessToken ) {
        const userData = {
            
            fromAdmin:false,
            env: environment.dbPrefix ,
            userData: {
                username: agent.displayName,
                id:agent.id,
                phone: agent.phone,
                nin: agent.nin,
                ninURL: agent.ninURL,
                status: agent.status,
                currentLimit:agent.currentLimit,
                availableCredit:agent.availableCredit,
                agencyId: agent.agencyId,
                accountUpdateDate : Date.now(),
                permissions: agent.permissions
              }
        };

        // const myUrl = environment.cloudfunctionurl + '/' + environment.dbPrefix + 'createStationManager';
        const myUrl = environment.containerurl  + 'agency/agent';
        const headers = { Authorization: 'Bearer ' + accessToken};
        return this.http.patch(myUrl, userData, {headers});
    }

    public createAccountFromSocial(agent ): Promise<any> {
        this.authInfo$.next(new AuthInfo(agent.id));
        let firstLogin = new Date().toISOString();
        if (agent.metadata) {
            firstLogin = agent.metadata.creationTime;
        }
        console.log('From service, ', agent)
        return this.stationmanagerDataServ.create({
                email: agent.email,
                id: agent.id,
                username: agent.username,
                provider: agent.provider,
                usertype: 'stationmanager',
                photoURL: agent.photoURL,
                phone: agent.phone,
                location: agent.location,
                transporter: agent.transporter,
                nin: agent.nin,
                ninURL: agent.ninURL,
                status: 'new',
                accountName: agent.accountName,
                accountBank: agent.accountBank,
                accountNumber: agent.accountNumber,
                bookedTrips : 0,
                totalAmountBooked : 0,
                availableCredit : 0,
                accountUpdateDate : Date.now(),
                firstLogin,
                notificationArray: []
                });

    }

    public login(email: string, password: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.fireAuth.auth.signInWithEmailAndPassword(email, password)
                .then(res => {
                    if (res.user) {
                        this.authInfo$.next(new AuthInfo(res.user.uid));
                        resolve(res.user);
                    }
                })
                .catch(err => {

                    this.authInfo$.next(AuthenticationService.UNKNOWN_USER);
                    reject(`login failed ${err}`);
                });
        });
    }

    public logout(): Promise<void> {
        this.authInfo$.next(AuthenticationService.UNKNOWN_USER);
        return this.fireAuth.auth.signOut();
    }
    public checkAuth() {
        return new Promise((resolve) => {
            this.fireAuth.auth.onAuthStateChanged(user => {
                resolve(user);
             });
        });
    }
    public loginWithFacebook(accessToken) {
        const credential = firebase.auth.FacebookAuthProvider
            .credential(accessToken);
        return this.fireAuth.auth.signInWithCredential(credential);
    }
    public fbLogin(): Promise<any> {
        return this.fireAuth.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider());
    }
    public loginWithTwitter(accessToken, accessSecret) {
        const credential = firebase.auth.TwitterAuthProvider
            .credential(accessToken, accessSecret);
        return this.fireAuth.auth.signInWithCredential(credential);
    }
    public twitterLogin(): Promise<any> {
        return this.fireAuth.auth.signInWithPopup(new firebase.auth.TwitterAuthProvider());
    }
    public loginWithGoogle(accessToken, accessSecret) {
        // console.log("secret:",accessSecret)
        const credential = accessSecret ? firebase.auth.GoogleAuthProvider
            .credential(accessToken, accessSecret) : firebase.auth.GoogleAuthProvider
            .credential(accessToken);

        return this.fireAuth.auth.signInWithCredential(credential);
    }
    public googleLogin(): Promise<any> {
        return this.fireAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
    }
    public createSocialLoginUser(user): Promise<any> {
        this.authInfo$.next(new AuthInfo(user.uid));
        let firstLogin = new Date().toISOString();
        if (user.metadata) {
            firstLogin = user.metadata.creationTime;
        }

        const data = {
            email: user.email,
            id: user.uid,
            provider: this.loginProviderId ? this.loginProviderId : user.providerId,
            usertype: 'stationmanager',
            firstLogin
          };

        if (user.displayName ) {
        data['username'] = user.displayName;
        }

        if (user.photoURL) {
            data['photoURL'] = user.photoURL;
            }

        return this.stationmanagerDataServ.create(data);
    }



    public createAppleLoginUser(user, firstName, lastName): Promise<any> {
        console.log(user.uid);
        this.authInfo$.next(new AuthInfo(user.uid));
        let firstLogin = new Date().toISOString();
        if (user.metadata) {
            firstLogin = user.metadata.creationTime;
        }
        const data = {
            email: user.email,
            id: user.uid,
            provider: this.loginProviderId ? this.loginProviderId : user.providerId,
            usertype: 'stationmanager',
            firstLogin
          };

        if (firstName && lastName ) {
        data['username'] = firstName + ' ' + lastName;
        }

        if (user.photoURL) {
            data['photoURL'] = user.photoURL;
            }
        return this.stationmanagerDataServ.create(data); // this.firestore.create('users', data);
    }

    async loginWithApple(appleResponse) {
        // Create a custom OAuth provider
        const provider = new firebase.auth.OAuthProvider('apple.com');

        // Create sign in credentials with our token
        const credential = provider.credential({
          idToken: appleResponse.identityToken
        });

        // Call the sign in with our created credentials
        //
        return await this.fireAuth.auth.signInWithCredential(credential);
    }
}
